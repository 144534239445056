<template>
  <div class="app login-page flex-row align-items-center element">
    <!-- reset pass  -->
    <v-row class="justify-content-center">
      <v-col cols="1"> &nbsp; </v-col>
      <v-col cols="10" sm="10" md="6" lg="5">
        <v-row class="login-row">
          <!-- Admin and Reception Login -->
          <v-card class="cards pa-5 login-card" v-if="!empLogin">
            <!-- login div -->
            <div v-if="!resetPassDialog" class="login ps-3 pe-3 cards pa-5">
              <h5 class="text-center mb-6 blue-grey--text text--darken-3">
                برای ورود به سیستم، لطفا نام کاربری و رمز ورود خود را وارد
                نمایید.
              </h5>
              <v-col>
                <v-text-field
                  label="نام کاربری"
                  v-model="username"
                  class="login-input"
                ></v-text-field>
                <v-text-field
                  label="رمز عبور"
                  v-model="password"
                  class="login-input"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                  @keyup.enter="password && username ? gotoDashboard() : ''"
                ></v-text-field>
                <!--<v-col cols="8" class="mt-2">-->
                <a
                  class="primary--text"
                  @click="showResetPass"
                  style="
                    border: 0;
                    float: left;
                    font-weight: bold;
                    font-size: 14px;
                  "
                >
                  <i class="icon-login"></i>رمز عبور را فراموش کرده‌اید؟
                </a>
              </v-col>

              <!--</v-col>-->
              <v-row class="mt-5">
                <v-col class="text-center">
                  <v-btn
                    :loading="busy"
                    type="submit"
                    class="primary-btn w-100 pa-6 font-weight-bold"
                    @click="gotoDashboard()"
                    :disabled="busy || !(password && username)"
                    style="border: 0"
                  >
                    ورود
                  </v-btn>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    type="submit"
                    class="secondary-btn w-100 pa-6 font-weight-bold"
                    @click="
                      empLogin = true;
                      empMobile = '';
                    "
                    style="border: 0"
                  >
                    ورود کادر درمان
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col class="text-center">
                  <v-btn
                    class="purple-btn w-100 pa-8 font-weight-bold"
                    @click="$router.push('/patientPanel/main')"
                    style="border: 0"
                  >
                    ثبت درخواست بیمار
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <!-- reset pass div -->
            <div v-if="resetPassDialog" class="login ps-3 pe-3 cards pa-5">
              <h4 class="text-center mb-5 login-text primary--text">
                بازیابی رمز
              </h4>
              <v-card-text class="pa-0">
                <v-container>
                  <v-row class="justify-content-center">
                    <v-col>
                      <!-- NOTE here, the user will enter the mobile num  -->
                      <div v-if="!codeSent">
                        <h5
                          class="text-center mb-8 blue-grey--text text--darken-3"
                        >
                          برای بازیابی رمز عبور شماره همراه خود را وارد کنید
                        </h5>
                        <v-text-field
                          @keyup.enter.native="
                            !isBusy && mobile ? sendCode() : ''
                          "
                          type="number"
                          label="شماره تلفن همراه"
                          v-model="mobile"
                          class="w-100"
                        ></v-text-field>
                      </div>
                      <!-- NOTE then after the activation code has been sent, here, the user will enter 
            the activation and the new pass -->
                      <div v-else>
                        <h5
                          class="text-center mb-10 blue-grey--text text--darken-3"
                        >
                          لطفا کد ارسال شده را به همراه رمز عبور جدید وارد
                          نمایید.
                        </h5>
                        <v-text-field
                          type="number"
                          label="کد ارسالی"
                          v-model="activationCode"
                        ></v-text-field>
                        <v-text-field
                          label="رمز عبور جدید"
                          v-model="newPass"
                          class="my-3"
                          :type="show2 ? 'text' : 'password'"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show2 = !show2"
                        ></v-text-field>
                        <v-text-field
                          label="تکرار رمز عبور جدید"
                          v-model="repeatNewPass"
                          :type="show3 ? 'text' : 'password'"
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show3 = !show3"
                          @keyup.enter="
                            newPass &&
                            repeatNewPass &&
                            activationCode &&
                            !isBusy
                              ? resetPass()
                              : ''
                          "
                        ></v-text-field>
                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            lg="6"
                            class="center-row"
                          >
                            <v-btn
                              type="submit"
                              class="primary-btn py-5"
                              @click="resetPass()"
                              style="border: 0; width: 100%"
                              :disabled="
                                isBusy ||
                                !(repeatNewPass && newPass && activationCode)
                              "
                            >
                              تغییر رمز
                            </v-btn>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            lg="6"
                            class="center-row"
                          >
                            <!-- NOTE (b-sendAgain) to get the activation code again -->
                            <v-btn
                              type="submit"
                              class="primary-btn py-5"
                              @click="sendCode()"
                              style="border: 0; width: 100%"
                              :disabled="sendAgainDisbaled"
                            >
                              ارسال مجدد کد

                              <span v-if="countdown != 0">{{
                                toHHMMSS(countdown)
                              }}</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <!--<v-card-actions>-->
              <v-row class="mx-auto">
                <v-col
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  :cols="codeSent ? '6' : ''"
                  v-if="!codeSent"
                >
                  <v-btn
                    @click="sendCode()"
                    outlined
                    class="w-100 primary-btn py-5 font-weight-bold"
                    :disabled="isBusy || !mobile"
                  >
                    ارسال کد
                  </v-btn></v-col
                >
                <v-col
                  :cols="codeSent ? '12' : '6'"
                  :class="codeSent ? 'mt-4' : ''"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-btn
                    class="w-100 secondary-btn py-5 font-weight-bold"
                    outlined
                    @click="closeResetPass()"
                  >
                    بازگشت
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class=""> </v-row>
              <!--</v-card-actions>-->
            </div>
          </v-card>

          <!-- Doctor and Nurse Login -->
          <v-card v-if="empLogin" class="cards pa-5 login-card">
            <div class="login ps-3 pe-3 py-5">
              <h2 class="text-center mb-2 login-text primary--text">
                ورود کادر درمان
              </h2>

              <!-- Doctor and Nurse Login with Mobile Number and Password -->
              <div v-if="empWithPass && !empWithCode">
                <v-card-text class="pa-0">
                  <v-container>
                    <v-row class="justify-content-center">
                      <v-col>
                        <!-- login div -->
                        <div v-if="!empResetPassDialog" class="login cards">
                          <h5
                            class="text-center mb-6 blue-grey--text text--darken-3"
                            style="line-height: 26px"
                          >
                            برای ورود به سیستم، لطفا شماره تلفن همراه و رمز ورود
                            خود را وارد نمایید.
                          </h5>
                          <v-col>
                            <v-text-field
                              label="شماره تلفن همراه"
                              v-model="empMobile"
                              type="number"
                            ></v-text-field>
                            <v-text-field
                              label="رمز عبور"
                              v-model="empPassword"
                              class="login-input"
                              :type="show4 ? 'text' : 'password'"
                              :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="show4 = !show4"
                              @keyup.enter="
                                empPassword && empMobile
                                  ? toEmpDashboardPass()
                                  : ''
                              "
                            ></v-text-field>
                            <!--<v-col cols="8" class="mt-2">-->
                            <a
                              class="primary--text"
                              @click="showEmpResetPass"
                              style="
                                border: 0;
                                float: left;
                                font-weight: bold;
                                font-size: 14px;
                              "
                            >
                              <i class="icon-login"></i>رمز عبور را فراموش
                              کرده‌اید؟
                            </a>
                          </v-col>

                          <!--</v-col>-->
                          <v-row class="mt-5">
                            <v-col class="text-center">
                              <v-btn
                                :loading="busyEmp"
                                type="submit"
                                class="primary-btn w-100 pa-5 font-weight-bold"
                                @click="toEmpDashboardPass()"
                                :disabled="
                                  busyEmp || !(empPassword && empMobile)
                                "
                                style="border: 0"
                              >
                                ورود
                              </v-btn>
                            </v-col>
                            <v-col class="text-center">
                              <v-btn
                                type="submit"
                                class="secondary-btn w-100 pa-5 font-weight-bold"
                                @click="
                                  empWithCode = true;
                                  empMobile = '';
                                "
                                style="border: 0"
                              >
                                ورود با رمز یکبار مصرف
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <v-btn
                                style="margin: auto"
                                class="secondary-btn w-100 py-5 font-weight-bold"
                                outlined
                                @click="
                                  empLogin = false;
                                  empMobile = '';
                                  empPassword = '';
                                "
                              >
                                بازگشت
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                        <!-- reset pass div -->
                        <div v-if="empResetPassDialog" class="login cards">
                          <h4 class="text-center mb-5 login-text primary--text">
                            بازیابی رمز
                          </h4>
                          <v-card-text class="pa-0">
                            <v-container>
                              <v-row class="justify-content-center">
                                <v-col>
                                  <!-- user enters number here   -->
                                  <div v-if="!empCodeSent2">
                                    <h5
                                      class="text-center mb-8 blue-grey--text text--darken-3"
                                      style="line-height: 30px"
                                    >
                                      برای بازیابی رمز عبور شماره همراه خود را
                                      وارد کنید.
                                    </h5>

                                    <v-text-field
                                      @keyup.enter.native="
                                        !isBusy2 && empMobile2
                                          ? empSendCode2()
                                          : ''
                                      "
                                      type="number"
                                      label="شماره تلفن همراه"
                                      v-model="empMobile2"
                                      class="w-100"
                                    ></v-text-field>
                                    <span v-show="false">{{ reactivity }}</span>
                                    <v-row class="mx-auto mt-3">
                                      <v-col
                                        style="
                                          display: flex;
                                          justify-content: center;
                                          align-items: center;
                                        "
                                        cols="12"
                                        md="6"
                                      >
                                        <v-btn
                                          @click="empSendCode2()"
                                          outlined
                                          class="w-100 primary-btn py-5 font-weight-bold"
                                          :disabled="!empMobile2"
                                        >
                                          ارسال کد
                                        </v-btn></v-col
                                      >
                                      <v-col
                                        cols="12"
                                        md="6"
                                        style="
                                          display: flex;
                                          justify-content: center;
                                          align-items: center;
                                        "
                                      >
                                        <v-btn
                                          class="w-100 secondary-btn py-5 font-weight-bold"
                                          outlined
                                          @click="closeEmpResetPass()"
                                        >
                                          بازگشت
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <!-- NOTE that after the activation code has been sent, the user will enter 
            the code and the new pass -->
                                  <div v-else>
                                    <h5
                                      class="text-center mb-10 blue-grey--text text--darken-3"
                                    >
                                      لطفا کد ارسال شده را به همراه رمز عبور
                                      جدید وارد نمایید.
                                    </h5>
                                    <v-text-field
                                      type="number"
                                      label="کد ارسالی"
                                      v-model="empActivationCode2"
                                    ></v-text-field>
                                    <v-text-field
                                      label="رمز عبور جدید"
                                      v-model="newEmpPass"
                                      class="my-3"
                                      :type="show5 ? 'text' : 'password'"
                                      :append-icon="
                                        show5 ? 'mdi-eye' : 'mdi-eye-off'
                                      "
                                      @click:append="show5 = !show5"
                                    ></v-text-field>
                                    <v-text-field
                                      label="تکرار رمز عبور جدید"
                                      v-model="repeatNewEmpPass"
                                      :type="show6 ? 'text' : 'password'"
                                      :append-icon="
                                        show6 ? 'mdi-eye' : 'mdi-eye-off'
                                      "
                                      @click:append="show6 = !show6"
                                      @keyup.enter="
                                        newEmpPass &&
                                        repeatNewEmpPass &&
                                        empActivationCode2 &&
                                        !isBusy2
                                          ? resetEmpPass()
                                          : ''
                                      "
                                    ></v-text-field>
                                    <v-row class="mt-2">
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        lg="6"
                                        class="center-row"
                                      >
                                        <v-btn
                                          type="submit"
                                          class="primary-btn py-5 w-100"
                                          @click="resetEmpPass()"
                                          :disabled="
                                            isBusy2 ||
                                            !(
                                              repeatNewEmpPass &&
                                              newEmpPass &&
                                              empActivationCode2
                                            )
                                          "
                                        >
                                          تغییر رمز
                                        </v-btn>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        lg="6"
                                        class="center-row"
                                      >
                                        <!-- NOTE (b-sendAgain) to get the activation code again -->
                                        <v-btn
                                          type="submit"
                                          class="primary-btn py-5 w-100"
                                          @click="empSendCode2()"
                                          :disabled="empSendAgainDisabled2"
                                        >
                                          ارسال مجدد کد
                                          <span v-if="empCountdown2 != 0">{{
                                            toHHMMSS(empCountdown2)
                                          }}</span>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        style="
                                          display: flex;
                                          justify-content: center;
                                          align-items: center;
                                        "
                                      >
                                        <v-btn
                                          class="w-100 secondary-btn py-5 font-weight-bold"
                                          outlined
                                          @click="empCodeSent2 = false"
                                        >
                                          بازگشت
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </div>

              <!-- Doctor and Nurse Login with Code -->
              <div v-if="empWithCode">
                <v-card-text class="pa-0">
                  <v-container>
                    <v-row class="justify-content-center">
                      <v-col>
                        <!-- NOTE here, the user will enter the mobile num  -->
                        <div v-if="!empCodeSent">
                          <h5
                            class="text-center mb-8 blue-grey--text text--darken-3"
                          >
                            برای ورود به سامانه شماره همراه خود را وارد کنید.
                          </h5>
                          <v-text-field
                            @keyup.enter="
                              !empIsBusy && empMobile ? empSendCode() : ''
                            "
                            type="number"
                            label="شماره تلفن همراه"
                            v-model="empMobile"
                            class="w-100"
                          ></v-text-field>
                          <v-row>
                            <v-col
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <v-btn
                                @click="empSendCode()"
                                outlined
                                class="w-100 primary-btn py-5"
                                :disabled="empIsBusy || !empMobile"
                              >
                                ارسال کد
                              </v-btn></v-col
                            >
                            <v-col
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <v-btn
                                class="w-100 secondary-btn py-5"
                                outlined
                                @click="empWithCode = false"
                              >
                                بازگشت
                              </v-btn></v-col
                            >
                          </v-row>
                        </div>
                        <!-- NOTE then after the activation code has been sent, here, the user will enter 
            the activation code-->
                        <div v-else>
                          <h5
                            class="text-center mb-8 blue-grey--text text--darken-3"
                          >
                            لطفا کد ارسال شده را وارد نمایید.
                          </h5>
                          <v-text-field
                            type="number"
                            label="کد ارسالی"
                            v-model="empActivationCode"
                            @keyup.enter.native="
                              empActivationCode ? goToEmpDashboard() : ''
                            "
                          ></v-text-field>
                          <v-row class="mt-2">
                            <v-col cols="12">
                              <v-btn
                                :loading="empBusy"
                                type="submit"
                                class="primary-btn py-5 w-100"
                                @click="goToEmpDashboard()"
                                :disabled="empBusy || !empActivationCode"
                              >
                                ورود</v-btn
                              >
                            </v-col>
                            <v-col cols="12">
                              <!-- NOTE (b-sendAgain) to get the activation code again -->
                              <v-btn
                                type="submit"
                                class="primary-btn py-5 w-100"
                                @click="empSendCode()"
                                :disabled="empSendAgainDisabled"
                              >
                                ارسال مجدد کد

                                <span v-if="empCountdown != 0">{{
                                  toHHMMSS(empCountdown)
                                }}</span>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                              cols="12"
                              v-if="empCodeSent"
                            >
                              <v-btn
                                class="secondary-btn py-5 w-100"
                                outlined
                                @click="empCodeSent = false"
                              >
                                بازگشت
                              </v-btn></v-col
                            >
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-row>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import md5 from "js-md5";
import { ClientJS } from "clientjs";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      mobile: "",
      password: "",
      busy: false,
      isBusy: false,
      isBusy2: false,
      codeSent: false,
      activationCode: "",
      newPass: "",
      repeatNewPass: "",
      countdown: 60,
      resetPassDialog: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      fingerPrint: "",
      empLogin: false,
      empMobile: "",
      empMobile2: "",
      empIsBusy: false,
      empIsBusy2: false,
      empCodeSent: false,
      empCodeSent2: false,
      empActivationCode: "",
      empActivationCode2: "",
      empCountdown: 60,
      empCountdown2: 60,
      empBusy: false,
      empWithCode: false,
      empWithPass: true,
      empResetPassDialog: false,
      empPassword: "",
      newEmpPass: "",
      repeatNewEmpPass: "",
      reactivity: false,
      empSendAgainDisabled: false,
      empSendAgainDisabled2: false,
      busyEmp: false,
    };
  },
  computed: {},
  methods: {
    ...mapMutations(["setUserName", "setRole"]),

    //Admin and Reception Login Functions
    gotoDashboard() {
      // showing dash by sending our user and pass then with checking our information by server info we get our dash
      this.busy = true;
      this.$http
        .post(this.baseUrl + "/clinic/auth/adminLogin", {
          username: this.username,
          password: md5(this.password),
          fingerPrint: this.fingerPrint,
        })
        .then((res) => {
          this.busy = false;
          if (res.status == 200) {
            localStorage.clear();
            localStorage.setItem("token", res.data.token);

            localStorage.setItem("role", res.data.role);
            let role = res.data.role;

            this.setUserName(res.data.name);
            this.setRole(role);
            if (role == "reception") {
              this.$router.push("/reception/dashboard");
            } else if (role == "admin" || role == "manager") {
              this.$router.push("/admin/dashboard");
            } else if (role == "dentistryNurse") {
              this.$router.push("/dentistryNurse/dashboard");
            } else if (role == "dentistryAdmin") {
              this.$router.push("/dentistryAdmin/dentistryReport");
            } else {
              this.$router.push("/labReception/laboratoryRequests");
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    showResetPass() {
      // this.$refs["resetPassModal"].show();
      this.resetPassDialog = true;
      this.codeSent = false;
      this.mobile = "";
      this.activationCode = "";
      this.newPass = "";
      this.repeatNewPass = "";
    },
    closeResetPass() {
      // this.$refs["resetPassModal"].hide();
      this.mobile = "";
      this.activationCode = "";
      this.newPass = "";
      this.repeatNewPass = "";
      this.codeSent = false;
      this.resetPassDialog = false;
    },
    sendCode() {
      // NOTE (m-sendCode) to send the code
      this.codeSent = false;
      this.isBusy = true;
      this.$http
        .post(this.baseUrl + "/clinic/auth/adminsActivationcode", {
          mobile: this.mobile,
        })
        .then((res) => {
          this.codeSent = true;
          this.isBusy = false;
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    resetPass() {
      // if our newpass characters was more than 5 and new pass was equal to repeat pass reset pass
      if (this.newPass.length > 5) {
        if (this.newPass == this.repeatNewPass) {
          this.isBusy = true;
          this.$http
            .post(this.baseUrl + "/clinic/auth/adminsRecover", {
              mobile: this.mobile,
              password: md5(this.newPass),
              activationcode: this.activationCode,
            })
            .then((res) => {
              this.toast("رمز عبور شما با موفقیت تغییر کرد", "success");
              this.isBusy = false;
              this.closeResetPass();
            })
            .catch((err) => {
              this.toast("خطا: " + err.response.data, "error");
              this.isBusy = false;
            });
        } else {
          this.toast("رمز عبور و تکرار آن باید منطبق باشند", "error");
        }
      } else {
        this.toast("رمز عبور باید بیشتر از ۵ نویسه داشته باشد", "error");
      }
    },

    //Doctor and Nurse Login Functions
    empSendCode() {
      // NOTE (m-sendCode) to send the code
      this.empCodeSent = false;
      this.empIsBusy = true;
      this.$http
        .post(this.baseUrl + "/clinic/auth/login", {
          mobile: this.empMobile,
        })
        .then((res) => {
          this.empCodeSent = true;
          this.empIsBusy = false;
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.empIsBusy = false;
        });
    },
    empSendCode2() {
      // NOTE (m-sendCode) to send the code (for resetting password)
      this.empCodeSent2 = false;
      this.empIsBusy2 = true;
      this.$http
        .post(this.baseUrl + "/clinic/auth/medicalTeamSendCode", {
          mobile: this.empMobile2,
        })
        .then((res) => {
          this.empCodeSent2 = true;
          this.empIsBusy2 = false;
          this.reactivity = !this.reactivity;
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.empIsBusy2 = false;
        });
    },
    goToEmpDashboard() {
      //Go to doctor/nurse dashboard via mobile number and activation code
      this.empBusy = true;
      this.$http
        .post(this.baseUrl + "/clinic/auth/entrance", {
          mobile: this.empMobile,
          activationCode: this.empActivationCode,
        })
        .then((res) => {
          this.empBusy = false;
          if (res.status == 200) {
            localStorage.clear();
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            let role = res.data.role;
            localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
            localStorage.setItem("isDentist", res.data.isDentist);
            localStorage.setItem("isDentistNurse", res.data.isDentistNurse);
            this.setUserName(res.data.name);
            this.setRole(role);
            if (role == "doctor") {
              this.$router.push("doctor/dashboard");
            } else if (role == "nurse" || role == "dentistryNurse") {
              this.$router.push("/nurse/dashboard");
            } else {
              this.$router.push("doctor/dashboard");
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.empBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    toEmpDashboardPass() {
      //to to doctor/nurse dashboard via mobile number and password
      this.busyEmp = true;
      this.$http
        .post(this.baseUrl + "/clinic/auth/login", {
          mobile: this.empMobile,
          password: md5(this.empPassword),
        })
        .then((res) => {
          this.busyEmp = false;
          if (res.status == 200) {
            localStorage.clear();
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            let role = res.data.role;
            localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
            localStorage.setItem("isDentist", res.data.isDentist);
            localStorage.setItem("isDentistNurse", res.data.isDentistNurse);
            this.setUserName(res.data.name);
            this.setRole(role);
            if (role == "doctor") {
              this.$router.push("doctor/dashboard");
            } else if (role == "nurse" || role == "dentistryNurse") {
              this.$router.push("/nurse/dashboard");
            } else {
              this.$router.push("doctor/dashboard");
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.busyEmp = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    showEmpResetPass() {
      this.empResetPassDialog = true;
      this.empCodeSent2 = false;
      this.empMobile2 = "";
      this.empActivationCode2 = "";
      this.newEmpPass = "";
      this.repeatEmpNewPass = "";
    },
    closeEmpResetPass() {
      this.empMobile2 = "";
      this.empActivationCode2 = "";
      this.newEmpPass = "";
      this.repeatEmpNewPass = "";
      this.empCodeSent2 = false;
      this.empResetPassDialog = false;
    },
    resetEmpPass() {
      // if our newpass characters was more than 5 and new pass was equal to repeat pass reset pass
      if (this.newEmpPass.length > 5) {
        if (this.newEmpPass == this.repeatNewEmpPass) {
          this.isBusy2 = true;
          this.$http
            .post(this.baseUrl + "/clinic/auth/medicalTeamRecoverPassword", {
              mobile: this.empMobile2,
              password: md5(this.newEmpPass),
              activationcode: this.empActivationCode2,
            })
            .then((res) => {
              this.toast("رمز عبور شما با موفقیت تغییر کرد", "success");
              this.isBusy2 = false;
              this.closeEmpResetPass();
            })
            .catch((err) => {
              this.toast("خطا: " + err.response.data, "error");
              this.isBusy2 = false;
            });
        } else {
          this.toast("رمز عبور و تکرار آن باید منطبق باشند", "error");
        }
      } else {
        this.toast("رمز عبور باید بیشتر از ۵ نویسه داشته باشد", "error");
      }
    },

    //Activation code functions
    toHHMMSS(value) {
      // NOTE (m-toHHMMSS) determine the order of hours, minutes and seconds
      var sec_num = parseInt(value, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    },
    countDownTimer() {
      //NOTE (m-countDownTimer) to start counting down after 1 second
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    empCountDownTimer() {
      //NOTE (m-countDownTimer) to start counting down after 1 second
      if (this.empCountdown > 0) {
        setTimeout(() => {
          this.empCountdown -= 1;
          this.empCountDownTimer();
        }, 1000);
      }
    },
    empCountDownTimer2() {
      //NOTE (m-countDownTimer) to start counting down after 1 second
      if (this.empCountdown2 > 0) {
        setTimeout(() => {
          this.empCountdown2 -= 1;
          this.empCountDownTimer2();
        }, 1000);
      }
    },
  },
  watch: {
    codeSent: function (val, oldVal) {
      // NOTE (w-codeSent) to give the code again after 60 second
      this.countdown = 60;

      if (val == true) {
        this.sendAgainDisbaled = true;
        this.countDownTimer();
      }
    },
    countdown: function (val, oldVal) {
      // NOTE (w-countdown) to countdown for sending code again
      if (val == 0) {
        this.sendAgainDisbaled = false;
      } else {
        this.sendAgainDisbaled = true;
      }
    },
    empCodeSent: function (val, oldVal) {
      // NOTE (w-codeSent) to give the code again after 60 second
      this.empCountdown = 60;
      if (val == true) {
        this.empSendAgainDisabled = true;
        this.empCountDownTimer();
      }
    },
    empCodeSent2: function (val, oldVal) {
      // NOTE (w-codeSent) to give the code again after 60 second
      this.empCountdown2 = 60;
      if (val == true) {
        this.empSendAgainDisabled2 = true;
        this.empCountDownTimer2();
      }
    },
    empCountdown: function (val, oldVal) {
      // NOTE (w-countdown) to countdown for sending code again
      if (val == 0) {
        this.empSendAgainDisabled = false;
      } else {
        this.empSendAgainDisabled = true;
      }
    },
    empCountdown2: function (val, oldVal) {
      // NOTE (w-countdown) to countdown for sending code again
      if (val == 0) {
        this.empSendAgainDisabled2 = false;
      } else {
        this.empSendAgainDisabled2 = true;
      }
    },
  },
  mounted() {
    const client = new ClientJS();
    this.fingerPrint = md5(client.getCanvasPrint());
    console.log(md5(client.getCanvasPrint()));
  },
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
